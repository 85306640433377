html {
  font-size: 16px;
  font-family: $body-font;
  color: var(--color-black);
  background-color: var(--color-white);
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
}
