/* CSS HSL */
:root {
  /* CSS HEX */
  --columbia-blue: #cce6f4ff;
  --rich-black-fogra-29: #01161eff;
  --sunray: #edb458ff;
  --steel-blue: #427aa1ff;
  --mauve-taupe: #805e73ff;
  --yellow-green-crayola: #c5e99bff;
  --isabelline: #efe9e7ff;

  --brand-light-blue: var(--columbia-blue);
  --brand-blue: var(--steel-blue);
  --brand-yellow: var(--sunray);
  --brand-purple: var(--mauve-taupe);
  --brand-green: var(--yellow-green-crayola);
  --brand-white: var(--isabelline);
  --brand-black: var(--rich-black-fogra-29);
}

/* SCSS HSL */
$columbia-blue: hsla(201, 65%, 88%, 1);
$rich-black-fogra-29: hsla(197, 94%, 6%, 1);
$mauve-taupe: hsla(323, 15%, 44%, 1);
$yellow-green-crayola: hsla(88, 64%, 76%, 1);
$isabelline: hsla(15, 20%, 92%, 1);
$sunray: #edb458ff;
$steel-blue: #427aa1ff;

/* Brand color mappings */
$brand-blue: var(--brand-blue);
$brand-black: var(--brand-black);
$brand-green: var(--brand-green);
$brand-purple: var(--brand-purple);
$brand-white: var(--brand-white);
$brand-light-blue: var(--columbia-blue);
$brand-yellow: var(--brand-yellow);

/* Utility Class Maps */
$brandColors: ("blue" :$brand-blue, "black":$brand-black, "green":$brand-green, "purple":$brand-purple, "white":$brand-white, "light-blue": $brand-light-blue, "yellow": $brand-yellow);

@each $label,
$color in $brandColors {
  .text-#{$label} {
    color: $color;
  }

  .bg-#{$label} {
    background-color: $color;
  }
}


// OBS Text Colors
// TODO remove these at some point
.c-yellow-100 {
  color: var(--c-yellow-100);
}

.c-blue-100 {
  color: var(--c-blue-100);
}

.c-orange-100 {
  color: var(--c-orange-100);
}

.c-green-100 {
  color: var(--c-green-100);
}

.c-purple-100 {
  color: var(--c-purple-100);
}

.c-black-100 {
  color: var(--c-black-100);
}

.c-white-100 {
  color: var(--c-white-100);
}



// Backgrounds
.bg-yellow-100 {
  background-color: var(--c-yellow-100);
}

.bg-blue-100 {
  background-color: $brand-blue;
}

.bg-orange-100 {
  background-color: var(--c-orange-100);
}

.bg-green-100 {
  background-color: var(--c-green-100);
}

.bg-purple-100 {
  background-color: var(--c-purple-100);
}

.bg-black-100 {
  background-color: var(--c-black-100);
}

.bg-white-100 {
  background-color: var(--c-white-100);
}
