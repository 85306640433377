.job-overview {

  .company {
    font-weight: bold;
  }
}

.job-description {

  p,
  li {
    margin-bottom: 1rem;
  }

  li {
    list-style-type: disc;
    padding-left: .5rem;
    margin-left: 1rem;
  }
}
