@tailwind base;
@tailwind components;
@tailwind utilities;

@import "04-utilities/mixins";

@import "01-config/variables";
@import "01-config/typography";
@import "01-config/color";

@import "02-base/base";
@import "02-base/layout";

@import "03-components/header";
@import "03-components/footer";
@import "03-components/experience";

@import "05-projects/project-page";
@import "05-projects/graber-blinds-carousel-redesign";
@import "05-projects/widen-link-generator";
@import "05-projects/power-grid";
@import "05-projects/gallery";
