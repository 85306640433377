.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  opacity: .85;
  transition: all .3s ease;
  background-color: $brand-black;
  z-index: 100;

  &:hover {
    // background-color: lighten($brand-pu, 2.5%);
    opacity: 1;
  }
}


.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.site-nav {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  color: $brand-light-blue;

  .nav-item {
    margin: .5rem;

    &:hover {
      text-decoration: underline;
    }
  }
}

.jg-logo {
  width: 5rem;
  height: 5rem;
  margin: .5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--color-primary);
  text-align: center;
  position: relative;

  &:hover {}

  .offset-square {
    background-color: $brand-green;
    position: absolute;
    width: 3rem;
    height: 3rem;
    // left: -.65rem;
    // top: -.65rem;
    z-index: 0;
    opacity: 0.75;
  }

  .logo-inner {
    width: 4rem;
    height: 4rem;
    z-index: 1;
  }
}

.social-icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    margin: 1rem;
  }
}
