@mixin breakpoint($class) {

  @if $class==sm {
    @media (min-width: 640px) {
      @content;
    }
  }

  @else if $class==md {
    @media (min-width: 768px) {
      @content;
    }
  }

  @else if $class==lg {
    @media (min-width: 1024px) {
      @content;
    }
  }

  @else if $class==xl {
    @media (min-width: 1280px) {
      @content;
    }
  }

  @else {
    @warn "Breakpoint mixin supports: sm, md, lg, xl";
  }
}

// Magic parallax mixins

$parallax-perspective : 1 !default;
$parallax-element : "body" !default;
$parallax-ios : true !default;

@mixin parallax-init($perspective : $parallax-perspective,
  $element : $parallax-element,
  $parallax-ios : $parallax-ios) {
  @if $element=="body" {

    html,
    body {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      overflow: hidden;
    }
  }

  #{$element} {
    overflow: auto;
    perspective: $perspective * 1px;
    transform-style: preserve-3d;

    // Allows for smooth scrolling but disables parallax effects.
    @if $parallax-ios==false {
      -webkit-overflow-scrolling: touch;
    }

    // Preserve 3D
    &,
    * {
      transform-style: preserve-3d;
    }
  }
}

@mixin parallax($distance : 0,
  $perspective : $parallax-perspective) {
  transform:
    translateZ($distance * $perspective * 1px) scale(abs($distance - 1));
  z-index: $distance * 1000;
}

// End of magic parallax mixins
@mixin dc($color, $textColor) {
  // dc is short for dynamic color
  background: $color;
  color: text($textColor);
}
