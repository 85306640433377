.heading-xxl,
%heading-xxl {
  font-family: $heading-font;
  font-size: $size-scale-base * 5.063;
  line-height: $line-height;
  font-weight: bold;
}

.heading-xl,
%heading-xl {
  font-family: $heading-font;
  font-size: $size-scale-base * 3.375;
  line-height: $line-height;
  font-weight: bold;
}

.heading-lg,
%heading-lg {
  font-family: $heading-font;
  font-size: $size-scale-base * 2.25;
  line-height: $line-height;
  font-weight: bold;
}

.heading-md,
%heading-md {
  font-family: $heading-font;
  font-size: $size-scale-base * 1.5;
  line-height: $line-height;
  font-weight: bold;
}

.heading-sm,
%heading-sm {
  font-family: $heading-font;
  line-height: $line-height;
  font-size: $size-scale-base * 1.25;
  font-weight: bold;
}

.heading-base,
%heading-base {
  font-family: $heading-font;
  font-size: $size-scale-base;
  line-height: $line-height;
  font-weight: bold;
}



.body-lg,
%body-lg {
  font-family: $body-font;
  line-height: $line-height;
  font-weight: 400;
  font-size: $size-scale-base * 1.5;
}

.body-base,
%body-base {
  font-family: $body-font;
  font-weight: 400;
  line-height: $line-height;
  font-size: $size-scale-base;
}

.body-sm,
%body-sm {
  font-family: $body-font;
  line-height: $line-height;
  font-size: $size-scale-base * .75;
}
