.home--page,
.base-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: $brand-white;
}

.home--body {
  scroll-snap-type: y mandatory;
}

.site-section {
  padding-top: 200px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  height: max-content;
  scroll-snap-align: start;
}

.about {

  .about--portrait {
    height: auto;
    max-width: 30rem;
    max-height: 36rem;
  }
}

.projects-container {
  justify-content: space-between;

  .project {
    flex-grow: 1;

    .color-block {
      width: 100%;
      height: 10rem;
    }

    &:nth-child(even) {
      margin-left: 0rem;
    }

    &:nth-child(odd) {
      margin-right: 0rem;
    }

    &.disabled {
      opacity: .25;
      pointer-events: none;
    }
  }


}
